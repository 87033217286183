<template>
    <div>
        <div>
            <div class="closeBtn mb-2 text-right">
                <b-button variant="danger" size="sm" @click="close()">Close</b-button>
            </div>
            <h4 class="text-center" style="font-size: 24px; font-weight: 900">
                Score Summary for: <span class="text-info">{{ exam.name }}</span>
            </h4><br>
            <b-container fluid>
                <b-row>
                    <b-col md="12">
                        <div>
                            <table class="table table-bordered table-striped" id="institutionUserDetails">
                                <thead class="thead-dark" style="font-size: 14px; font-weight: 600">
                                    <tr>
                                        <th>Sl No.</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>
                                            Total Score
                                        </th>
                                        <th>Percentage</th>
                                    </tr>
                                </thead>
                                <tbody style="border: 0px;" v-for="(student, studentIndex) in students" :key="studentIndex">
                                    <tr style="font-size: 14px; font-weight: 600">
                                        <td class="p-0">{{ studentIndex + 1 }}</td>
                                        <td class="p-0 pl-1 text-left">
                                            {{ student.name }}
                                        </td>
                                        <td class="p-0 pl-1 text-left">
                                            <span v-if="student.institution_user_id"> Internal </span>
                                            <span v-if="!student.institution_user_id"> External </span>
                                        </td>
                                        <td class="align-middle text-left">
                                            <span>{{ updateTotalMarks[student.id] }}/{{ (totalMarks).toFixed(1) }}</span>
                                        </td>
                                        <td class="align-middle text-left">
                                            <span v-if="updateTotalMarks[student.id]">
                                                <span>{{
                                                    Math.floor(
                                                        (updateTotalMarks[student.id] / totalMarks) *
                                                        100
                                                    ) + "%"
                                                }}</span>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {
    required,
    requiredIf,
    integer
} from "vuelidate/lib/validators";
export default {
    async created() {
        this.fetchData = true;
        this.examId = this.$route.params.exam_id;
        await this.prepareTable();
    },
    components: {
    },
    data() {
        return {
            smsCount: null,
            fetchData: false,
            loadExams: false,
            deleteQuestionIndex: null,
            examId: null,
            exam: {},
            questions: [],
            courseModulesOptions: [],
            courseoutcomesOptions: [],
            importFromExcel: false,
            students: [],

            saveBtn: {
                text: "Save",
                disabled: false,
            },
            totalScores: {}, // processed on updateTotalMarks computed proprty
            totalMarks: null, // processed on updateTotalQuestionScore method
            zIndex: -1,
            edit: false,
            courseModulesOptionsObject: {},
            orquestiongroups: {},
            orGroupQuestionIds: {}, // to calculate student totel score 
            // enableOrQuestiongroupmodel: false,
            viewHeader: {
                maxMark: true,
                co: true,
                module: true,
                blooms: true,
                orq: true,
            },
            importExcelFile: null,
            excelImportStatus: "",
            viewImportExcelModel: false,

            expandedEvaluationStudId: null,
            activeStudentQuestionsScores: {}, //pass to child
            autoEvaluationStatus: false,
            enableManageQuestionWindow: false,
            subQuestionParentIds: [], // avoid enter score for main question of subquestions
        };
    },
    computed: {
        evaluationQuestions() {
            return this.questions.filter((q) => {
                return !this.subQuestionParentIds.includes(q.id);
            });
        },
        updateTotalMarks() {
            this.students.forEach((student) => {
                this.totalScores[student.id] = student.score ? student.score : 0;
            });
            return this.totalScores;
        },
    },
    validations: {
        questions: {
            required,
            $each: {
                question_no: {
                    required,
                },
                maxMarks: {
                    required: requiredIf(function () {
                        return this.exam.positive_mark == null;
                    }),
                    integer,
                },
            },
        },
    },

    methods: {
        close(){
            this.closeCurrentTab();
        },
        getExam() {
            const examId = this.examId;
            const url = this.$store.getters.getAPIKey.mainAPI +
                `/publicexams/${examId}`;
            axios
                .get(url)
                .then((response) => {
                    this.exam = {};
                    this.exam = response.data;
                    response;
                })
                .catch((error) => {
                    error;
                });
        },
        async prepareTable() {
            this.getExam();
            const url =
                this.$store.getters.getAPIKey.mainAPI +
                `/score_summary/${this.examId}`;
            await axios
                .get(url)
                .then((response) => {
                    this.students = response.data.participants;
                    this.questions = response.data.questions;
                    this.questions.forEach((ques) => {
                        this.totalMarks += Number(ques.maximum_mark);
                    });
                    this.answers = response.data.answers;
                })
                .catch(() => {
                    this.$toast.error("Failed to load", {
                        position: "top",
                        duration: 3000,
                    });
                });
        },
    },
};
</script>

<style lang="scss">
// .offlineExam table thead tr:last-child {
//   z-index: none !important;
// }
.offlineExam table {
    width: 100%;
    text-align: center;
}

// .subContentComponentCloseButton{
//   display: none !important;
// }

// input {
//   padding: 0.2rem !important;
// }

// th td {
//   padding: 0.5rem !important;
// }

// table tr:first-child th {
//   position: sticky;
//   top: 0px;
// }
</style>
